//
// Until I figure out a clean way of getting all of rearmed added
// I can't add all of it due to Object name clashes with datatables
require('../../../node_modules/rearmed-js/array')

var country_italculable = function(destorigin) {
  var country = $('#modal_quote_' + destorigin + '_country_id')
  if (!country.val()) { return false } // Its blank
  if (!country.select2('data')) { return true } // AJAX is being slow. Assume true.
  return country.select2('data').italculator
}

var toggleITALculatorButton = function () {
  if (country_italculable('origin') && country_italculable('destination')) {
    $('#modal-italculator-button').prop('disabled', false);
  } else {
    $('#modal-italculator-button').prop('disabled', true);
  }
}

function initQuoteCommodityCategory(entity_selector, commodity_selector) {
  var $entity = $(entity_selector);
  var $commodity = $(commodity_selector);

  $(entity_selector).change(function(event) {
    var data = $entity.select2('data') || {};
    $commodity.data('allow-forwarder-only', data.forwarding_agent);
    $commodity.val(data.commodity_category_id);
    $commodity.trigger('change');
  });
}

var isCountryGB = function (selector) {
  return ($(selector).select2('data') || {}).country_code == 'GB'
}

var incotermCode = function (origin_gb, destination_gb) {
  if (origin_gb + destination_gb != 1) { return '' } // cross trade or domestic
  if (origin_gb) { return "DAP" } // Export
  if (destination_gb) { return "EXW" } // Import
}

var defaultQuoteIncoterm = function (prefix) {
  var incoterm = $('#'+prefix+'quote_incoterm_id')
  if (incoterm.val()) { return } // already set
  var origin_gb = isCountryGB('#'+prefix+'quote_origin_country_id')
  var destination_gb = isCountryGB('#'+prefix+'quote_destination_country_id')
  var id = incoterm.find('option:contains('+incotermCode(origin_gb, destination_gb)+')').val()
  incoterm.val(id).trigger('change')
}

// Used in quick quote modal and quote edit
// We use the same form with two actions by changing the url as appropriate
var defaultIncoterm = function (event) {
  var prefix = event.currentTarget.id.slice(0, 5) == "modal" ? "modal_" : "";
  defaultQuoteIncoterm(prefix)
}

// Change footer buttons depending on tab..
let initButtonsForTabs = function () {
  let create_quote = $('#modal-quote-submit')
  let new_rate = $('#modal-get-tariff-button')
  let new_customer_tariff = $('#modal-new-customer-tariff-button')
  let create_customer_tariff = $('#modal-create-customer-tariff-button')

  let all_buttons = [create_quote, new_rate, new_customer_tariff, create_customer_tariff]

  let showButtons = function (...buttons) {
    buttons.each(x => x.show());
    all_buttons.reject(x => buttons.includes(x)).each(x => x.hide());
  }

  $('#modal-customer-tariff-tab-button').on('click', function () {
    if ($('#tab-modal-customer-tariff').find('input').length) {
      showButtons(create_customer_tariff)
    }
    else {
      showButtons(/* none */)
    }
  })

  $('#quote-modal-tariff-tab-button').on('click', function () {
    showButtons(create_quote, new_customer_tariff)
  })

  $('#quote-modal-customer-rate-tab-button').on('click', function () {
    showButtons(create_quote)
  })

  // The rest...
  $('#quote-modal-tabs').find('a').not(
    '#modal-customer-tariff-tab-button, #quote-modal-tariff-tab-button, ' +
    '#quote-modal-customer-rate-tab-button'
  ).on('click', function() {
    showButtons(create_quote, new_rate)
  })
}

// Init all the magic in the new quote modal
window.initQuoteModal = function() {
  var $modal = $('#modal');

  $modal.uniquifyDimensionsLines(); // In case we're editing a Quote or Job!
  $modal.initTemperatureControlled();
  $modal.initAutoTotalDims();
  $modal.initDimensionsDetails();

  $modal.find("select.js-town-postcode-select").change(quoteLocationChange).change();
  $('#modal-get-tariff-button').on('click', defaultIncoterm);
  $('#modal').find('.magic-submit').on('click', changeFormUrl)

  initButtonsForTabs()

  $('#modal-italculator-button').click(function(event) {
    // Show the extra details tabs
    $(`#quote-modal-details-tab-button, #quote-modal-advanced-tab-button,
       #quote-modal-dangerous-goods-tab-button`).show()

    $('#quote-modal-details-tab-button').click()

    // opening before visible messes things up:
    setTimeout(function() {
      $('#modal_quote_shipping_location:visible').select2('open');
    }, 200);

    // Hide this button / Show the "ITALculate rates" button instead:
    $('#modal-get-tariff-button').show();
    $(event.currentTarget).hide();
  });

  var $dangerous_goods = $('#tab-modal-quote-dangerous-goods');
  $dangerous_goods.find(".add_nested_fields").focus(function () {
    $(this).click();
  });
  $dangerous_goods.on('nested:fieldAdded', initQuickQuoteDG);
  initQuickQuoteDG();


  $('#modal_quote_origin_country_id, #modal_quote_destination_country_id')
  .on('change', toggleITALculatorButton)

  setTimeout(function () { toggleITALculatorButton(), 1000 })

  // Default commodity category when entity changed
  initQuoteCommodityCategory(
    '#modal_quote_customer_id',
    '#modal_quote_commodity_category_id'
  );

  $('#tab-modal-quote-basic, #tab-modal-quote-italculator, #tab-modal-quote-advanced')
  .find('input').on('change', invalidateTariffRate)


  $('#modal_quote_tail_lift_on_collection').on('change', function(event) {
    if (event.currentTarget.checked)
      $('#modal_quote_origin_category').select2('val', 'Domestic');
  });
  $('#modal_quote_tail_lift_on_delivery').on('change', function(event) {
    if (event.currentTarget.checked)
      $('#modal_quote_destination_category').select2('val', 'Domestic');
  });
  // Show a warning if personal effects selected
  $('#modal_quote_commodity_category_id').on('change', function (event) {
    if (!($(event.currentTarget).select2('data') || {}).personal_effects) { return }
    $.notify({
      message: "<h4>Personal Effects</h4>Domestic transport may be " +
               "required; can we take an HGV to the client's home?"
    }, {
      element: $('#modal'),
      type: 'warning',
      mouseover: 'pause',
      delay: 12000
    })
  })

  $('#modal_quote_shipping_location, #modal_quote_delivery_location').on('change', autoPostcode)

}

const AUTO_FIELDS = {
  modal_quote_shipping_location: {
    country:  '#modal_quote_origin_country_id',
    postcode: '#modal_quote_shipping_postcode_id'
  },
  modal_quote_delivery_location: {
    country:  '#modal_quote_destination_country_id',
    postcode: '#modal_quote_delivery_postcode_id'
  }
}

let autoPostcode = function (e) {
  if (e.currentTarget.value != 'Auto') { return true }

  let fields = AUTO_FIELDS[e.currentTarget.id]
  let customer = $('#modal_quote_customer_id').select2('data') || {}

  if (customer.country_id == $(fields.country).val()) {
    $(fields.postcode).val(customer.postcode_id).trigger('change')
    $(e.currentTarget).val('Postcode').trigger('change')
  } else {
    $.notify({ message: "Customer country does not match" }, { element: $('#modal') })
  }
}

// Tariff tabs are stale once inputs change
var invalidateTariffRate = function() {
  $(`#quote-modal-tariff-tab-button,
     #modal-customer-tariff-tab-button,
     #quote-modal-customer-rate-tab-button`).hide()
  $(`#tab-modal-quote-tariff,
     #tab-modal-customer-tariff,
     #tab-modal-customer-rate`).children().remove()
}

// We need a unique id for the container for the DG ajax functions
var initQuickQuoteDG = function (event) {
  var container = event ? $(event.field) : $('#tab-modal-quote-dangerous-goods').children()
  container.filter('.js-dg-container').each(function() {
    var field = $(this)
    var id = field.find('.dgli-id')
    id = id.val() || id.attr('id').match(/\d+/)[0]
    field.attr('id', 'quick-quote-dgli-' + id)
    field.dgInit()
    field.find('input').on('change', invalidateTariffRate)
    invalidateTariffRate()
  })
}

// When quote contact is changed, set the customer to be the contact's entity
// if the entity is blank
var setCustomerToThatOfContact = function (contact_id) {
  // Ensure customer has no existing value first
  if ($('#quote_customer_id').val()) { return; }
  // Find the details of the contact
  $.ajax("/contacts/" + contact_id + ".json", {
    dataType: "json"
  }).done(function (data) {
    // Find the contact's entity
    var entity_id = data.entity_id;
    if (entity_id) {
      $.ajax("/entities/" + entity_id + ".json", {
        dataType: "json"
      }).done(function (data) {
        // Only continue if the entity is a customer
        if (data.customer_id) {
          // Format the results in the way select2 needs it
          var selected = {id: data.id, text: data.name};
          $('#quote_customer_id').select2("data", selected);
          $('#quote_customer_id').trigger("change");
        } else {
          $('#quote_customer_id').select2("val", '');
          $('#quote_customer_id').trigger("change");
        }
      });
    } else {
      $('#quote_customer_id').select2("val", '');
      $('#quote_customer_id').trigger("change");
    }
  });
};
window.setCustomerToThatOfContact = setCustomerToThatOfContact;

var quoteUpdateCostTotal = function () {
  var $modal = $("#modal");
  var x = totalLineItems($modal).join(' ').replace("GBP", "£").replace("USD", "$");
  $modal.find(".js-totalled-costs").html(x.replace("EUR", "€").replace("JPY", "¥"));
};
window.quoteUpdateCostTotal = quoteUpdateCostTotal;

// Show a warning next to loading metres if the customer is listed as "never
// stack", but the quote is *not* flagged as "do not stack"
var showOrHideLoadingMetresNotStackableWarning = function () {
  var show = false;
  var json = $("#quote_customer_id, #job_customer_id").select2("data");
  if (json && json.never_stack && $("#quote_do_not_stack, #job_do_not_stack").is(":checked")) {
    show = true;
  }
  $('.js-do-not-stack-warning').toggle(show);
};

// This function is called to set up callbacks when the page first loads.
// NB: also for jobs
document.addEventListener("turbolinks:load", function () {
  var i, k, elem;

  initQuoteCommodityCategory('#quote_customer_id', '#commodity-category-id');

  // Round various fields to a given number of decimal places (see jobs.js).
  var round_to = {
    quote_gross_weight_kg: 0,
    // Cubic metres: see js-cubic_metres
    quote_net_weight_kg: 0,
    quote_loading_metres: 2
  };

  for (k in round_to) {
    $("#" + k).off().change(function () { roundNumberFieldUp($(this), round_to[this.id]); });
  }

  // Show "postcode" / "place" / "hub" / "partial postcode":
  $("select.js-town-postcode-select").change(quoteLocationChange).each(function () {
    quoteLocationChange.call(this); // Don't trigger change, side effects!
  })

  $('.js-multi-quote-line-items-table').on('nested:fieldAdded', function (event) {
    $(event.target).find('select.js-town-postcode-select').change(quoteLocationChange).change();
    $("[name$='[multi_type]']").change();  // <-- Hide the unnecessary fields
  });

  // If the entity changes, change the "new contact" link to use the new value.
  var updateContactButton = function() {
    var a = document.getElementById('quote_contact_btn');
    if (!a) { return; }
    var custy_val = $('#quote_customer_id').val();
    if (custy_val) {
      a.href = a.href.replace(/entity_id=\d+/, 'entity_id=' + String(custy_val));
      a.removeAttribute("disabled");
    } else {
      a.setAttribute("disabled", "true");
    }
  };
  updateContactButton();
  $('#quote_customer_id').on('change', updateContactButton);

  // If an ORIGINAL price is given (by another quote, which this has been cloned
  // from) then we'll need to verify that the price should remain unchanged...
  var original_price = $("#quote-original-price");
  if ($('#quote-original-price').val()) {
    // The original price is stored in 'GBP 123.45' format.
    // This function should return FALSE if the form shouldn't be submitted...
    $("#quote-form-container").find('form.quote-form').on('submit', function() {
      var old_price = $('#quote-original-price').val();
      var new_price = totalLineItems($("#quote-sales-line-items-list")).join(' ');
      if (old_price == new_price) {
        return confirm("The total price is still "+old_price+". Okay to save?");
      } else {
        return true;
      }
    });
  }

  // Open the 'send email' modal if the user wanted it..
  if (location.search.indexOf("submit_action=pdf") > -1) {
    $("#send-quote-pdf").click();
  }

  // The select is not submitted, but affects the input
  $('#freight-frequency-select').on('change', function(event) {
    if (event.currentTarget.value == 'Other') {
      $('#freight-frequency-input').val('').show();
    } else {
      $('#freight-frequency-input').val(event.currentTarget.value).hide();
    }
  });

  // Update the "about" info in the left-hand bar.
  $("#quote_customer_id, #job_customer_id").change(function () {
    $("#about_entity_name").html('');
    $("#about_entity_details").html('');
    if (this.value) { $.ajax("/entities/" + String(this.value) + ".js"); }
  });

  // FMS-2523: Show 'Must charge VAT' for EU customer personal effects quotes
  $('#quote_customer_id, #commodity-category-id').on('change', function() {
    var customer_data = $('#quote_customer_id').select2('data');
    var commodity_data = $('#commodity-category-id').select2('data');
    if (!customer_data || !commodity_data) { return; }

    if (customer_data.trade_agreement == 'eu' && commodity_data.personal_effects) {
      $('#vat-info').show();
    } else {
      $('#vat-info').hide();
    }
  });

  // If the customer changes (and isn't blank!) update the info on the left.
  var updateQuotesHistory = function () {
    var customer = $("#quote_customer_id").val();
    if (customer) {
      $.ajax("/entities/" + customer + "/quote_history.js" +
             getQuoteHistoryParams());
    } else {
      $("#quote_history").html("");
    }
  };
  var getQuoteHistoryParams = function () {
    return (
      "?origin_country_id=" + $('#quote_origin_country_id').val() +
      "&destination_country_id=" + $('#quote_destination_country_id').val() +
      "&commodity_category_id=" + $('#commodity-category-id').val() +
      "&shipping_place_id=" + $('#quote_shipping_place_id').val() +
      "&shipping_hub_id=" + $('#quote_shipping_hub_id').val() +
      "&delivery_place_id=" + $('#quote_delivery_place_id').val() +
      "&delivery_hub_id=" + $('#quote_delivery_hub_id').val() +
      "&shipping_postcode_id=" + $('#quote_shipping_postcode_id').val() +
      "&delivery_postcode_id=" + $('#quote_delivery_postcode_id').val() +
      "&cargo_hazardous=" + $('#quote_cargo_hazardous').prop('checked') +
      "&temperature_controlled=" + $('#quote_temperature_controlled').prop('checked') +
      "&overlength=" + $('#quote_overlength').prop('checked') +
      "&full_load=" + $('#quote_full_load').val() +
      "&do_not_stack=" + $('#quote_do_not_stack').prop('checked') +
      "&do_not_transship=" + $('#quote_do_not_transship').prop('checked') +
      "&do_not_part_load=" + $('#quote_do_not_part_load').prop('checked') +
      "&do_not_break_down=" + $('#quote_do_not_break_down').prop('checked') +
      "&tail_lift_on_collection=" + $('#quote_tail_lift_on_collection').prop('checked') +
      "&tail_lift_on_delivery=" + $('#quote_tail_lift_on_delivery').prop('checked') +
      "&insurance_needed=" + $('#quote_insurance_needed').prop('checked') +
      "&customs_formalities=" + $('#quote_customs_formalities').prop('checked') +
      "&incoterm_id=" + $('#incoterm_id').val() +
      "&quote_id=" + $('#quote_history').data('quote-id') +
      "&per_page=" + $('#quote_history').data('per-page'));
  };
  updateQuotesHistory();

  $('#quote_customer_id, #quote_origin_country_id, ' +
    '#quote_destination_country_id, #commodity-category-id, ' +
    '#quote_shipping_place_id, #quote_shipping_hub_id, ' +
    '#quote_delivery_hub_id, #quote_delivery_place_id, ' +
    '#quote_shipping_postcode_id, #quote_delivery_postcode_id,' +
    '#quote_cargo_hazardous, #quote_temperature_controlled,' +
    '#quote_overlength, #quote_full_load,' +
    '#quote_do_not_stack, #quote_do_not_transship,' +
    '#quote_do_not_part_load, #quote_do_not_break_down,' +
    '#quote_tail_lift_on_collection, #quote_tail_lift_on_delivery,' +
    '#quote_insurance_needed, #quote_customs_formalities,' +
    '#incoterm_id').on('change', updateQuotesHistory);

  // Enable or disable fields based on the "direction" of this multi-quote:
  $("[name$='[multi_type]']").change(function () {
    if (this.value == "Multi-Origin") {
      var hide_selector = ".js-multi-destination";
      var show_selector = ".js-multi-origin";
    } else {
      var hide_selector = ".js-multi-origin";
      var show_selector = ".js-multi-destination";
    }
    $(hide_selector).prop("disabled", true ).closest("[id$='_editor'").hide();
    $(show_selector).prop("disabled", false).closest("[id$='_editor'").show();
  }).change();

  $(
    "#quote_customer_id, #job_customer_id, #quote_do_not_stack, #job_do_not_stack"
  ).change(showOrHideLoadingMetresNotStackableWarning);
});

